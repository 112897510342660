import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get(traceProcessId) {
    let result = await axios.get(
      `${API_ROUTES.traceprocesslawyersNotes.get}/${traceProcessId}`
    );

    return result;
  },

  async save(data) {
    let result = await axios.post(
      `${API_ROUTES.traceprocesslawyersNotes.save}`,
      data
    );

    return result;
  }
};
